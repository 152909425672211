const translation = {
  adult: 'Adult',
  adults: 'Adults',
  child: 'Child',
  infant: 'Infant',
  ticket: {
    select: 'Select Ticket',
    clear: 'Clear selection',
    child: 'Child',
    infant: 'Infant',
    perCar: 'Per Group',
    selected: 'Selected Ticket',
    moreInfo: 'More Info',
    adultTooltip: '3+ years old',
    childTooltip: '5 - 11.99 years old',
    infantTooltip: '0 - 4.99 years old',
    pricePerVehicle: 'Price per Vehicle * <1> Max 6 people</1>',
    time: 'Time',
    selectTime: 'Select time',
    total: 'Total',
    checkout: 'Checkout',
    selectDate: 'Please select date before proceeding',
    ticketInformation: 'Ticket Information',
    checkAvailability: 'Check Availability',
  },
  checkout: {
    guest: 'Guest Details',
    selectHotel: 'Select Hotel',
    enterYourName: 'Enter Your Name',
    enterYourRoom: 'Enter Your Room number',
    enterYourEmail: 'Enter Your Email',
    selectMobile: 'Enter Your Mobile Number',
    bookingSummary: 'Booking Summary',
    tourDate: 'Tour Date',
    pickUpTime: 'Pick Up Time',
    selectHotelFirst: 'Select Hotel First',
    ticketType: 'Ticket Type',
    numberOfPeopleLabel: 'Number of People',
    cancellationPolicyLabel: 'Cancellation Policy',
    cancellationPolicy: '48 Hours - 100% / 24 Hours - 50%',
    clock1: 'Hours',
    clock2: 'Hours',
    clock3: 'Hours',
    orMore: 'or more',
    returns: 'return',
    validUntil: 'Tickets valid until'
  },
  payment: {
    cardNumber: 'Card number',
    expirationDate: 'Expiration date',
    cvc: 'CVC',
    bookNow: 'Book now {{amount}} AED'
  },
  confirm: {
    title: 'Booking Confirmed',
    tourName: 'Tour Name',
    totalCost: 'Total Cost',
    guestName: 'Guest Name',
    hotelName: 'Hotel Name',
    roomNumber: 'Room number',
    mobileNumber: 'Mobile Number',
    email: 'Email ID',
    ticketInfo: 'A copy of your voucher has been sent to your email.',
    downloadVoucher: 'Download Voucher',
    downloadTickets: 'Download Tickets',
    downloadInvoice: 'Download Invoice',
    information: ' Incase any of the above information is incorrect or you have any\n further questions contact details below.',
    reservationTeamContacts: 'Reservation team contacts',
    questions: 'Incase you have any questions, please contact.'
  },
  faq: {
    title1: 'Can I pay cash onboard instead of credit card?',
    body1: 'We only accept pre-paid bookings outside of some exceptional cases. If you wish to speak to us about it, please click one of the contact us icons in the header.',
    title2: 'Can I get a refund if I cancel?',
    body2: 'Yes, if you wish to cancel please contact us and request a cancellation.\n {{cancellationPolicy}} ALL refunds incur a 2-3% processing fee that is largely dependent on the bank + card providers.\n',
    cancellationPolicy48: 'If it is 48 hours or more we will refund the {{percent}}% payment. \n',
    cancellationPolicy24: 'If its 24 hours or more, but less than 48, we will refund {{percent}}%.  \n',

    title3: 'Where do we need to go to?',
    body3: 'If you purchase a ticket including hotel transfers, we will pick you up from your hotel during the indicatedtime.\nIf you purchase a ticket without transfers, you will need to come to our location at 20.30 PM. All instructions will be provided in the final confirmation after payment.',
    title4: 'What is included onboard?',
    body4: 'All tickets include a cruise as well as a full course dinner and soft drinks. If you have purchased a package which includes unlimited house drinks, then you will have unlimited drinks from our bar.\nIf not, you will be able to order drinks from the bar at an extra charge.',

  }

}

export default translation;