import { agencyGuid, apiUrl } from '../../config/constants';
import { fetchRequest } from './index';
import { Booking, BookingRequest, PaymentIntent } from '../../types/booking';

const withPaymentIntent = async (
  request: BookingRequest
): Promise<{ booking: Booking; paymentIntent: PaymentIntent }> =>
  await fetchRequest.post(`${apiUrl}/bookings/withpaymentintent?AgencyGuid=${agencyGuid}`, request);

const getBooking = async (bookingId: string): Promise<Booking> =>
  await fetchRequest.get(`${apiUrl}/bookings/${bookingId}`);

const bookings = {
  withPaymentIntent,
  getBooking
};
export default bookings;
