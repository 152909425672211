import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { emptyDate, pixelID, googleConversionActionID } from '../config/constants';

export const timeFormat = (startTime: number, endTime: number) => `${format(addMinutes(emptyDate, startTime), 'HH-mm')} - ${format(
  addMinutes(emptyDate, endTime),
  'HH-mm'
)}`;

export const timeFormatFromTime = (time: number) => formatDate(addMinutes(emptyDate, time));

export const formatDate = (date: Date): string => {
  const hours24 = date.getHours();
  const hours12 = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const ampm = hours24 < 12 ? 'am' : 'pm';

  const hours12String = hours12.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');

  return `${hours12String}:${minutesString} ${ampm}`;
};

export const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export function getFBTrackingCode() {
    return `!function(f,b,e,v,n,t,s)
             {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
             n.callMethod.apply(n,arguments):n.queue.push(arguments)};
             if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
             n.queue=[];t=b.createElement(e);t.async=!0;
             t.src=v;s=b.getElementsByTagName(e)[0];
             s.parentNode.insertBefore(t,s)}(window, document,'script',
             'https://connect.facebook.net/en_US/fbevents.js');
             fbq('init', '${pixelID}');
             fbq('track', 'PageView');`;
  }
  
  export function getNoScriptFBTrackingCode() {
    return `<img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=${pixelID}&ev=PageView&noscript=1"
            />`;
  }
  
  export function googleConversion(conversionValue?: string, bookingId?: string) {
    return `window.gtag('event', 'conversion', {
                          'send_to': '${googleConversionActionID}',
                          'value': ${conversionValue},
                          'currency': 'USD',
                          'transaction_id': '${bookingId}'
                        })`;
  }
  
  export function fbConversion(conversionValue?: string) {
    return `fbq('track', 'Purchase', {
                value: ${conversionValue},
                currency: 'USD',
              });`;
  }