import ReactTooltip from 'react-tooltip';

type NumberInputProps = {
  label: string;
  tooltip?: string;
  price?: number;
  delPrice?: number;
  count?: number;
  minValue?: number;
  onChange: (value: number) => void;
};
const NumberInput = ({ label, tooltip, price, delPrice, minValue = 0, count = 0, onChange }: NumberInputProps) => {
  const counterChangeHandler = (newValue: number) => {
    if (newValue >= minValue) onChange(newValue);
  };
  return (
    <div className='number-input-container'>
      <div className='number-input-label'>
        <h6>
          {label}
          <span data-tip data-for={label}>
            i{' '}
          </span>
          <ReactTooltip id={label} place='top' type='info' effect='float' multiline={true}>
            {tooltip}
          </ReactTooltip>
        </h6>
        AED {price} {delPrice && <del>AED {delPrice}</del>}
      </div>
      <div className='counter-container'>
        <button
          className='counter-container__button counter-container__button_left'
          onClick={counterChangeHandler.bind(undefined, count - 1)}
        >
          -
        </button>
        <input type='number' className='counter-container__input' value={count} />
        <button
          className='counter-container__button counter-container__button_right'
          onClick={counterChangeHandler.bind(undefined, count + 1)}
        >
          +
        </button>
      </div>
      <div className='number-input-price'>{(count * (price || 0)).toFixed(0)} AED</div>
    </div>
  );
};

export default NumberInput;
