import queryString from 'query-string';
import { toast } from 'react-toastify';

/**
 * Основной метод, который отсылает запросы
 * @param url - ручка
 * @param paramsData - данные запроса
 */
export async function fetchWithTokenRequest(url: string, paramsData: any): Promise<any> {
  const { ...params } = paramsData;

  let headers = {
    ...params.headers
  };

  const response = await window.fetch(url, {
    ...params,
    headers
  });
  if (response.ok) {
    if (response.status === 204) {
      return null;
    }
    try {
      // Если в body лежит не json, то при response.text() мы получим ошибку, поэтому на свякий случай клонируем ответ сервера
      return await response.clone().json();
    } catch {
      console.warn('Cannot parse response as json');
      return await response.text();
    }
  } else {
    if (response.status === 503) {
      throw new Error('Server is under maintenance');
    } else if (response.status >= 400) {
      const resp = await response.text();
      toast.error(resp);
    } else {
      toast.error('Error');
    }
  }
}

export class fetchRequest {
  static async get(
    path: string,
    data?: any,
    options: any = {},
    seriailizeOptions: queryString.StringifyOptions = { arrayFormat: 'bracket' }
  ) {
    return await fetchWithTokenRequest(
      `${path}${data ? `?${queryString.stringify(data, seriailizeOptions)}` : ''}`,
      options
    );
  }

  static async post(path: string, data?: any, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'POST',
      body: JSON.stringify(data),
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers
      }
    });
  }

  static async file(path: string, data?: any, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'POST',
      body: data,
      ...options
    });
  }

  static async put(path: string, data: any, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'PUT',
      body: JSON.stringify(data),
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers
      }
    });
  }

  static async delete(path: string, options: any = {}) {
    return await fetchWithTokenRequest(path, {
      method: 'DELETE',
      ...options
    });
  }
}

export { default as ticketApi } from './ticket';
export { default as bookingApi } from './bookings';
