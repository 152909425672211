import React from 'react';
import { supportPhoneNumber, supportEmail, showPhone } from '../../config/constants';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { routes } from '../../config/constants';

import { faPhone } from '@fortawesome/fontawesome-free-solid';
import { faArrowAltCircleLeft, faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Header = () => {
  const backClick = () => {
    document.dispatchEvent(new CustomEvent('eventHeaderClick', { bubbles: true }));
  };
  console.log(showPhone)
  return (
    <header id='main-header'>
      <div className='container'>
        <div className='header-wrap'>
          <div className='header-contacts'>
            <button className='header__button' onClick={backClick}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </button>
          </div>
          <div className='header-logo'>
            <Link to={routes.main} className='main-logo'>
              <img src={logo} alt='Logo' />
            </Link>
          </div>
          <div className='header-icon'>
          {showPhone && (
            <a href={`https://api.whatsapp.com/send?phone=%2B${supportPhoneNumber.substring(1)}`}>
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          )}
          {showPhone && (
            <>
              <a className='header-phone_tablet' href={`tel:${supportPhoneNumber}`}>
                <FontAwesomeIcon icon={faPhone as IconProp} />
              </a>
              <a className='header-phone_desktop' href={`tel:${supportPhoneNumber}`}>
                <FontAwesomeIcon icon={faPhone as IconProp} />
              </a>
            </>
          )}
          <a href={`mailto:${supportEmail}`}>
            <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} />
          </a>
        </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
