import { useCallback, useEffect, useState } from 'react';
import Offer from './offer';
import { ticketApi } from '../../lib/api';
import { TicketType } from '../../types/ticket';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useHistory } from 'react-router-dom';
import { routes, mainSite } from '../../config/constants';
import Button from '../../components/button';
import { ReactComponent as IconRefresh } from '../../assets/images/icons/icon-refresh.svg';
import { addMonths, startOfDay } from 'date-fns';
import { Availability } from '../../types/availability';
import availabilityApi from '../../lib/api/availability';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getFBTrackingCode, getNoScriptFBTrackingCode } from '../../lib/utils';
import { useOffer } from '../../hooks/use-offer';

const handleEventHeaderClick = () => {
  window.location.href = mainSite;
};


const Ticket = () => {
  const [ticket, setTicket] = useState<TicketType>();
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderTimeSlot, setLoaderTimeSlot] = useState<boolean>(false);
  const history = useHistory();
  const [t] = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const data = await ticketApi.getTickets('aed');
      setTicket(data);
    };
    fetchData();
    document.addEventListener('eventHeaderClick', handleEventHeaderClick, false);
    return () => document.removeEventListener('eventHeaderClick', handleEventHeaderClick, false);
  }, []);

  const fetchAvailability = async (offerId: number, date: Date | undefined, isExternal: boolean): Promise<Availability[]> =>
  {
    isExternal ? setLoaderTimeSlot(true) : setLoader(true)
    let data: Availability[] = [];
    try {
      if (offerId && ((isExternal && date) || !isExternal)) {
        data = await availabilityApi.getByOffer(
          offerId,
          isExternal && date ? startOfDay(date || new Date()) : startOfDay(date || new Date()) <= addMonths(new Date(), 1) ? new Date() : startOfDay(date || new Date()),
          isExternal && date ? startOfDay(date) : addMonths(startOfDay(date || new Date()), 1),
        );

        if (isExternal) {
          setAvailabilities(data.length ? data : [{
            date: date,
            left: 0,
            offerId: offerId,
            startTime: '',
            endTime: ''
          } as Availability])
        } else {
          setAvailabilities((prevState) => [...prevState, ...data]);
        }
      }
    } finally {
      isExternal ? setLoaderTimeSlot(false) : setLoader(false)
    }
    return data;
  }

  const {
    editOfferObj,
    selectOffer,
    handleChangePeopleCount,
    loadMoreDates,
    selectDate,
    selectTimeSlot,
    clear
  } = useOffer(ticket, availabilities, setLoader, setLoaderTimeSlot, 'aed', fetchAvailability)

  const handleCheckoutBtn = useCallback(() => {
    if(!editOfferObj.offer?.childrenAllowed){
      editOfferObj.childCount = 0;
    }
    if(!editOfferObj.offer?.infantsAllowed){
      editOfferObj.infantCount = 0;
    }
    history.push(routes.checkout, editOfferObj)
  }, [editOfferObj, history]);


  return (
    <>
    <Helmet>
      <script>
        {getFBTrackingCode()}
      </script>
      <script>
        {getNoScriptFBTrackingCode()}
      </script>
    </Helmet>
      <h1 className='ticket-title'>{ticket?.name}</h1>
      <div className='ticket-select-label'>
        <div>{t('ticket.select')}</div>
        <div>
          {editOfferObj.offer && (
            <Button
              onClickHandler={clear}
              label={t('ticket.clear')}
              size='small'
              color='gray'
              iconLeft={() => <IconRefresh />}
            />
          )}
        </div>
      </div>
      <div className='ticket-wrap'>
      {ticket?.offers
        .sort((a, b) => {
          // Compare by bookingType first
          if (a.privateType < b.privateType) return -1;
          if (a.privateType > b.privateType) return 1;
          // If bookingType is the same, then compare by id
          return a.id - b.id; // Assuming id is a number. If it's a string, use localeCompare
        })
        .map((offer) => (
          <Offer
            key={offer.id}
            offer={offer}
            isSelected={offer.id === editOfferObj.offer?.id}
            selectHandler={selectOffer}
            heroImage={ticket.heroImage}
            editOfferObj={editOfferObj}
            handleCheckoutBtn={handleCheckoutBtn}
            handleChangePeopleCount={handleChangePeopleCount}
            selectDate={selectDate}
            selectTimeSlot={selectTimeSlot}
            availabilities={availabilities}
            loadMoreDates={loadMoreDates}
            utcOffset={ticket.utcOffset}
            loader={loader}
            loaderTimeSlot={loaderTimeSlot}
          />
        ))}
    </div>
    </>
  );
};

export default Ticket;
