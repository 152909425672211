import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Input from '../../components/input';
import { emptyDate, routes, stripePubKey, supportPhoneNumber } from '../../config/constants';
import { CheckoutData, FaqType  } from '../../types/ticket';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { ticketApi } from '../../lib/api';
import { getFBTrackingCode, getNoScriptFBTrackingCode }  from '../../lib/utils';
import SelectRS from '../../components/select-rs';
import { Hotel } from '../../types/hotel';
import Payment from '../../components/payment';
import { Elements } from '@stripe/react-stripe-js';
import Accordeon, { AccordeonItemType } from '../../components/accordeon';
import { BookingRequest } from '../../types/booking';
import { loadStripe } from '@stripe/stripe-js/pure';
import stripeJs from '@stripe/stripe-js';
import PhoneInput from 'react-phone-input-2';
import { declOfNum, timeFormat } from '../../lib/utils';
import { useTranslation } from 'react-i18next';
import citiesApi from '../../lib/api/cities';
import { City } from '../../types/city';
import { Helmet } from 'react-helmet';

const initialCheckout = {
  ticketName: '',
  offer: undefined,
  selectedDate: new Date(),
  adultCount: 1,
  childCount: 0,
  infantCount: 0
};

const validateEmail = (email: string) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

const Checkout = () => {
  const location = useLocation();
  const history = useHistory();
  const [t] = useTranslation();


  // States
  const [hotels, setHotels] = useState<{ label: string, value: number, hotel: Hotel }[]>([]);
  const [checkout, setCheckout] = useState<CheckoutData>(initialCheckout);
  const [filterSelect, setFilterSelect] = useState('');
  const [stripePromise, setStripePromise] = useState<PromiseLike<stripeJs.Stripe | null> | stripeJs.Stripe | null>();
  const [isFormValid, setIsFormValid] = useState({ userEmail: true, userPhone: true, guestName: true, hotel: true });
  const [cities, setCities] = useState<City[] | undefined>();

  // Memos
  const filteredHotel = useMemo(() => {
    return hotels.filter((item) => item.label.toLowerCase().startsWith(filterSelect));
  }, [hotels, filterSelect]);

  const {amount, amountUsd} = useMemo(
    () =>({amount: checkout.adultCount * (checkout.selectedPrice?.pricePerAdult || 0) +
        checkout.childCount * (checkout.selectedPrice?.pricePerChild || 0) +
        checkout.infantCount * (checkout.selectedPrice?.pricePerInfant || 0) +
        (checkout.selectedPrice?.pricePerTrip || 0),
      amountUsd: checkout.adultCount * (checkout.selectedPrice?.pricePerAdultUsd || 0) +
        checkout.childCount * (checkout.selectedPrice?.pricePerChildUsd || 0) +
        checkout.infantCount * (checkout.selectedPrice?.pricePerInfantUsd || 0) +
        (checkout.selectedPrice?.pricePerTripUsd || 0)})
      ,
    [
      checkout.adultCount,
      checkout.childCount,
      checkout.infantCount,
      checkout.selectedPrice?.pricePerAdult,
      checkout.selectedPrice?.pricePerAdultUsd,
      checkout.selectedPrice?.pricePerChild,
      checkout.selectedPrice?.pricePerChildUsd,
      checkout.selectedPrice?.pricePerInfant,
      checkout.selectedPrice?.pricePerInfantUsd,
      checkout.selectedPrice?.pricePerTrip,
      checkout.selectedPrice?.pricePerTripUsd
    ]
  );

  const bookingRequest: BookingRequest = useMemo(
    () => ({
      adults: checkout.adultCount,
      children: checkout.childCount,
      date: checkout.selectedPrice?.date,
      endTime: checkout.timeSlot?.endTime,
      guestName: checkout.guestName || '',
      hotelId: checkout.hotel?.id,
      infants: checkout.infantCount,
      offerId: checkout.offer?.id,
      email: checkout.userEmail,
      paymentType: 1,
      roomNumber: checkout.userRoomNumber,
      startTime: checkout.timeSlot?.startTime,
      timeSlotId: checkout.timeSlot?.id,
      tourId: checkout.tourId,
      phone: checkout.userPhone,
      totalCost: amount,
      totalCostUsd: amountUsd
    }),
    [checkout, amount, amountUsd]
  );

  const ticketOnClickHandler = useCallback(() => history.push(routes.ticket, checkout), [checkout, history]);

  // Effects
  useEffect(() => {
    const _stripePromise = loadStripe(stripePubKey);
    setStripePromise(_stripePromise);
  }, []);

  useEffect(() => {
    window.scroll({  top: 0,
      left: 0,
      behavior: "smooth" })
  }, [])

  useEffect(() => {
    if (checkout.offer && !checkout.offer.isDirectReporting) {
      const fetchData = async () => {
        const data = await ticketApi.getHotels(50);
        setHotels(
          data.map((item) => ({
            label: `${item.name} (${item.cityName})`,
            value: item.id,
            hotel: item,
          }))
        );
      };
      fetchData();
    }
    if (!location.state) {
      history.replace(routes.ticket);
    }
    const data = location.state as CheckoutData;
    setCheckout(data);
  }, [checkout.offer, checkout.offer?.isDirectReporting, history, location.state]);

  useEffect(() => {
    const fetch = async () => {
      const data = await citiesApi.getCities(50, 'en');
      setCities(data);
    };
    fetch();
  }, []);

  useEffect(() => {
    document.addEventListener(
      'eventHeaderClick',
      () => {
        ticketOnClickHandler();
      },
      false
    );

    return () =>
      document.removeEventListener(
        'eventHeaderClick',
        () => {
          ticketOnClickHandler();
        },
        false
      );
  }, [ticketOnClickHandler]);


  // Callbacks
  const setFieldCheckoutHandler = useCallback( (name: string, value: any) => {
    setCheckout((prev) => {
      if (prev) {
        let newState = { ...prev };
        // @ts-ignore
        newState[name] = value;
        return newState;
      }
      console.log('setFieldCheckoutHandler prev is null');
      return initialCheckout;
    });
    setIsFormValid((prev) => {
      let newState = { ...prev };
      if (name === 'userEmail') {
        // @ts-ignore
        newState[name] = !!validateEmail(value || '');
      } else if (name === 'hotel') {
      const hotel = hotels.find((item) => item.hotel === value);
      if (hotel) {
        newState[name] = checkout.offer?.cities.some((item) => Number(item) === hotel?.hotel.cityId) || false;
      } else {
        setFieldCheckoutHandler('pickUpLocation', value);
        newState[name] = !!value;
      }
      } else {
        // @ts-ignore
        newState[name] = !!value;
      }
      return newState;
    });

  }, [checkout.offer?.cities, hotels]);

  useEffect(() => {
    if (checkout.timeSlot && checkout.hotel) {
      const fetchData = async () => {
        const data = await ticketApi.getPickUp(
          checkout.timeSlot?.pickUpTimeId,
          checkout.hotel?.id,
          checkout.timeSlot?.startTime
        );
        setFieldCheckoutHandler('pickUpTime', data);
      };
      fetchData();
    }
  }, [checkout.hotel, checkout.timeSlot, setFieldCheckoutHandler]);


  const isValid = !!validateEmail(checkout.userEmail || '') && !!checkout.userPhone && !!checkout.guestName && (!!checkout.hotel || !!checkout.offer?.isDirectReporting);

  return (
    <>
    <Helmet>
      <script>
        {getFBTrackingCode()}
      </script>
      <script>
        {getNoScriptFBTrackingCode()}
      </script>
    </Helmet>
      <div className='checkout-container'>
        <h2 className='checkout-title'>{t('checkout.guest')}</h2>
        <div className='checkout-box-wrap checkout-box-details'>
          <form className='checkout-details-form'> 
            {!checkout.offer?.isDirectReporting && (
              <><SelectRS
                onChange={(value) => setFieldCheckoutHandler('hotel', value.hotel)}
                placeholder={t('checkout.selectHotel')}
                options={filteredHotel}
                onInputChange={setFilterSelect}
                error={!isFormValid.hotel}
              />
            {!isFormValid.hotel && (
              <div className="error">
              The tour & ticket combination you have selected is only valid from{' '}
            {cities?.filter(city => checkout.offer?.cities.includes(city.value)).map((city) => city.label)
              .join(', ')}
              . The hotel you have selected is from {checkout.hotel?.cityName}. Please reach out to us at {supportPhoneNumber} to make the reservation.
              </div>
              )}
              </>
            )}

            <div className='form-row'>
              <div className={!checkout.offer?.isDirectReporting ? 'form-col' : 'form-col-full'}>
                <Input
                  type='text'
                  onChange={(event) => setFieldCheckoutHandler('guestName', event.target.value)}
                  value={checkout.guestName}
                  placeholder={t('checkout.enterYourName')}
                  error={!isFormValid.guestName}
                />
              </div>
              {!checkout.offer?.isDirectReporting && (
                <div className='form-col'>
                  <Input
                    type='text'
                    onChange={(event) => setFieldCheckoutHandler('userRoomNumber', event.target.value)}
                    value={checkout.userRoomNumber}
                    placeholder={t('checkout.enterYourRoom')}
                  />
                </div>
              )}
            </div>
            <div className='form-row'>
              <div className='form-col'>
                <Input
                  type='email'
                  onChange={(event) => setFieldCheckoutHandler('userEmail', event.target.value)}
                  value={checkout.userEmail}
                  placeholder={t('checkout.enterYourEmail')}
                  error={!isFormValid.userEmail}
                />
              </div>
              <div className='form-col'>
                <PhoneInput
                  country={'ae'}
                  enableAreaCodes={true}
                  onChange={(event) => setFieldCheckoutHandler('userPhone', event)}
                  placeholder={t('checkout.selectMobile')}
                  value={checkout.userPhone}
                  inputProps={{
                    name: 'userPhone',
                    required: true
                  }}
                  isValid={(value, country) => {
                    // @ts-ignore
                    return isFormValid.userPhone || (value && value !== country.countryCode);
                  }}
                />
              </div>
            </div>
            {!checkout.offer?.isDirectReporting && (
              <span className='checkout-summary-label'>
                  {`If your hotel is not listed, or your pickup location is not at a hotel, please reach out to us at ${supportPhoneNumber}`}
              </span>
            )}
          </form>
        </div>
        <h2 className='checkout-title checkout-title_first'>{t('checkout.bookingSummary')}</h2>
        <div className='checkout-box-wrap checkout-box-summary'>
          <ul className='checkout-summary-list'>
            {checkout.offer?.bookingType !== 2 && (
              <li>
              <span className='checkout-summary-label'>{t('checkout.tourDate')}</span>
              {`${format(checkout.selectedDate || new Date(), 'dd.MM.yyyy')} (${
                checkout.timeSlot && timeFormat(checkout.timeSlot.startTime, checkout.timeSlot.endTime)
              })`}
            </li>
            )}
            {!checkout.offer?.isDirectReporting && checkout.hotel && (
              <li>
                <span className='checkout-summary-label'>{t('checkout.pickUpTime')}</span>
                {checkout.pickUpTime
                  ? `${format(addMinutes(emptyDate, checkout.pickUpTime.timeShiftStart), 'HH-mm')} - ${format(
                    addMinutes(emptyDate, checkout.pickUpTime.timeShiftEnd),
                    'HH-mm'
                  )}`
                  : t('checkout.selectHotelFirst')}
              </li>
            )}
            <li>
              <span className='checkout-summary-label'>{t('checkout.ticketType')}</span>
              {checkout.offer?.name}
            </li>
            {checkout.offer?.bookingType === 2 &&
             checkout?.ticketStock?.validUntil && (
              <li>
              <span className='checkout-summary-label'>{t('checkout.validUntil')}</span>
              {format(new Date(checkout.ticketStock?.validUntil), 'dd.MM.yyyy')}
              </li>
            )}
            {
              !checkout.offer?.childrenAllowed &&
              <li>
                <span className='checkout-summary-label'>{t('checkout.numberOfPeopleLabel')}</span>
                {checkout.adultCount} 
              </li>
            }
            {
              checkout.offer?.childrenAllowed &&
              <li>
                <span className='checkout-summary-label'>{t('checkout.numberOfPeopleLabel')}</span>
                {checkout.adultCount} {t('adults')} {checkout.childCount ? `/ ${checkout.childCount} ${t('child')}` : ''}{' '}
                {checkout.infantCount ? `/ ${checkout.infantCount} ${t('infant')}` : ''}
              </li>
            }
            <li>
              <span className='checkout-summary-label'>{t('checkout.cancellationPolicyLabel')}</span>
              {checkout.cancellationPolicy && (checkout.cancellationPolicy
                ?.sort((a, b) => (a.hours < b.hours ? 1 : -1))
                .map((item, index) => (
                  <>
                    {index > 0 && <br />}{' '}
                    {`${item.hours} ${declOfNum(item.hours, [t('checkout.clock1'), t('checkout.clock2'), t('checkout.clock3')])} ${
                      t('checkout.orMore')
                    } = ${item.percents}% ${t('checkout.returns')}`}
                  </>
                )))}
              {checkout.cancellationPolicy && checkout.cancellationPolicy.length===0 && (
                "Tickets are non-refundable"
              )}
            </li>
          </ul>
        </div>

        <div className='checkout-box-wrap checkout-box-payment'>
          <div className="payment-select-title">

          <>
            <svg width="120" height="74" viewBox="0 0 120 74" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M59.927 64.7888C53.6216 70.2301 45.4425 73.5148 36.5049 73.5148C16.5624 73.5148 0.395813 57.1606 0.395813 36.9865C0.395813 16.8125 16.5624 0.458252 36.5049 0.458252C45.4425 0.458252 53.6217 3.74307 59.9271 9.18439C66.2325 3.74312 74.4116 0.458333 83.3492 0.458333C103.292 0.458333 119.458 16.8126 119.458 36.9866C119.458 57.1606 103.292 73.5149 83.3492 73.5149C74.4116 73.5149 66.2324 70.2301 59.927 64.7888Z"
                fill="#ED0006"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M59.9271 64.7887C67.691 58.0888 72.6141 48.1193 72.6141 36.9865C72.6141 25.8538 67.691 15.8843 59.9271 9.18434C66.2325 3.74305 74.4116 0.458252 83.3492 0.458252C103.292 0.458252 119.458 16.8125 119.458 36.9865C119.458 57.1606 103.292 73.5148 83.3492 73.5148C74.4116 73.5148 66.2325 70.23 59.9271 64.7887Z"
                fill="#F9A000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M59.9272 64.7887C67.6911 58.0887 72.6141 48.1193 72.6141 36.9866C72.6141 25.854 67.6911 15.8845 59.9272 9.18457C52.1634 15.8845 47.2404 25.854 47.2404 36.9866C47.2404 48.1193 52.1634 58.0887 59.9272 64.7887Z"
                fill="#FF5E00"
              />
            </svg>
            <svg width="131" height="43" viewBox="0 0 131 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.2253 42.0335H22.0053L13.5916 9.9349C13.1923 8.45836 12.3444 7.15302 11.0971 6.5378C7.98435 4.99172 4.55433 3.76127 0.8125 3.1407V1.9049H18.8871C21.3816 1.9049 23.2526 3.76127 23.5644 5.91722L27.9299 29.071L39.1444 1.9049H50.0526L33.2253 42.0335ZM56.2891 42.0335H45.6927L54.4182 1.9049H65.0145L56.2891 42.0335ZM78.7237 13.0216C79.0355 10.8603 80.9064 9.62447 83.0892 9.62447C86.5192 9.31418 90.2555 9.93475 93.3737 11.4755L95.2446 2.83562C92.1265 1.59982 88.6964 0.979248 85.5837 0.979248C75.2991 0.979248 67.8155 6.53765 67.8155 14.252C67.8155 20.1207 73.1164 23.2022 76.8582 25.0585C80.9064 26.9095 82.4655 28.1453 82.1537 29.9964C82.1537 32.7729 79.0355 34.0087 75.9228 34.0087C72.1809 34.0087 68.4391 33.0832 65.0146 31.5371L63.1436 40.1823C66.8855 41.723 70.9337 42.3436 74.6755 42.3436C86.2074 42.6486 93.3737 37.0955 93.3737 28.7606C93.3737 18.2643 78.7237 17.6491 78.7237 13.0216V13.0216ZM130.458 42.0335L122.045 1.9049H113.007C111.136 1.9049 109.266 3.1407 108.642 4.99172L93.0619 42.0335H103.97L106.147 36.1701H119.55L120.797 42.0335H130.458ZM114.566 12.7111L117.679 27.8349H108.954L114.566 12.7111Z"
                fill="#172B85"
              />
            </svg>
            <div className="payment-description">
              <h5>Mastercard and Visa</h5>
              <span>Your card details are secured using 2048 bit SSL encryption.</span>
            </div>
          </>
        </div>
          {stripePromise && (
            <Elements stripe={stripePromise}>
              <Payment
                request={bookingRequest}
                amount={amount}
                ticketType={checkout.offer?.name || ''}
                isValid={isValid}
              />
            </Elements>
          )}
        </div>
        {checkout.faqList && (
           <Accordeon items={getFaq(checkout.faqList)} />
        )}
      </div>
    </>
  );
};

export default Checkout;

const getFaq = (faqList: FaqType[]): AccordeonItemType[] => {
  const faq = [];
  for (let entry of faqList) {
    faq.push(
      {
        title: entry.question,
        body: entry.answer
      }
    )
  }
  return faq;
};
