export const routes = {
  main: '/',
  ticket: '/',
  embeddedTicket: '/embedded',
  tour: '/tour',
  checkout: '/checkout',
  embeddedCheckout: '/embedded/checkout',
  confirm: '/confirm'
};
export const stripePubKey = process.env.REACT_APP_STRIPE_PUB_KEY || '';

export const appName = process.env.REACT_APP_NAME || '';

export const supportPhoneNumber = process.env.REACT_APP_SUPPORT_PHONE || '';

export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL || '';

export const mainSite = process.env.REACT_APP_MAIN_SITE || '';

export const pixelID = process.env.REACT_APP_PIXEL_ID || '';

export const googleConversionActionID = process.env.REACT_APP_GOOGLE_CONVERSION_ACTION || '';

export const agencyGuid = process.env.REACT_APP_AGENCY_GUID || '';

export const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

export const tourId =  process.env.REACT_APP_TOUR_ID || '';

export const showPhone = process.env.REACT_APP_SHOW_NUMBER === 'true';

export const emptyDate = new Date(2020, 1, 1, 0, 0, 0);

export const language = 'en';
