import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {  supportPhoneNumber, supportEmail, mainSite } from '../../config/constants';
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-info.svg';
import { ReactComponent as IconDownload } from '../../assets/images/icons/icon-download.svg';
import { ReactComponent as IconTicketActive } from '../../assets/images/icons/icon-ticket-active.svg';
import { ReactComponent as IconAccept } from '../../assets/images/icons/icon-accept.svg';
import { ReactComponent as IconPhone } from '../../assets/images/icons/icon-phone.svg';
import { ReactComponent as IconEmail } from '../../assets/images/icons/icon-email.svg';
import ContactAvatar from '../../assets/images/contact-avatar.png';
import Button from '../../components/button';
import { bookingApi } from '../../lib/api';
import { getFBTrackingCode, getNoScriptFBTrackingCode, googleConversion, fbConversion}  from '../../lib/utils';
import { Booking } from '../../types/booking';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { emptyDate } from '../../config/constants';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";


const handleEventHeaderClick = () => {
  window.location.href = mainSite;
};

const Confirm = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [t] = useTranslation();

  // States
  const [booking, setBooking] = useState<Booking>();
  const [isLoading, setLoading] = useState(true);

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      const data = await bookingApi.getBooking(bookingId);
      setBooking(data);
      setLoading(false)
      console.log(data)
    };
    fetchData();
    document.addEventListener('eventHeaderClick', handleEventHeaderClick, false);
    return () => document.removeEventListener('eventHeaderClick', handleEventHeaderClick, false);
  }, [bookingId]);

  function recordConversion() {
     if (isLoading){
        return null
     }
     return googleConversion(booking?.totalCostUsd, bookingId)
  }
  function recordFBConversion() {
    if (isLoading){
      return null
   }
    return fbConversion(booking?.totalCostUsd)
  }

  return (
    <>
    <Helmet>
      <script>
        {getFBTrackingCode()}
      </script>
      <script>
        {getNoScriptFBTrackingCode()}
      </script>
      <script>
        {recordConversion()}
      </script>
      <script>
        {recordFBConversion()}
      </script>
    </Helmet>
    <div className='confirm-container'>
      <h2 className='booking?-title'>{t('confirm.title')}</h2>
      <div className='confirm-table'>
        {booking && booking.startTime && booking.endTime && (
          <div className='confirm-table-row'>
            <div className='confirm-table-cell'>{t('checkout.tourDate')}</div>
            <div className='confirm-table-cell'>
            {booking &&
            `${format(new Date(booking.date), 'dd.MM.yyyy')} (${format(
              addMinutes(emptyDate, booking.startTime),
              'HH-mm'
            )} - ${format(addMinutes(emptyDate, booking.endTime), 'HH-mm')})`}
            </div>
          </div>
        )}
        {booking && booking.ticketsValidUntil && (
          <div className='confirm-table-row'>
            <div className='confirm-table-cell'>{t('checkout.validUntil')}</div>
            <div className='confirm-table-cell'>
              {format(
                  new Date(booking.ticketsValidUntil) || new Date(),
                  'dd.MM.yyyy'
                )}
            </div>
          </div>
        )}
        {booking?.hotelName && (
          <div className='confirm-table-row'>
            <div className='confirm-table-cell'>{t('checkout.pickUpTime')}</div>
            <div className='confirm-table-cell'>{`${format(
              addMinutes(emptyDate, booking.pickUpTimeStart),
              'HH-mm'
            )} - ${format(addMinutes(emptyDate, booking.pickUpTimeEnd), 'HH-mm')}`}</div>
          </div>
        )}
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.tourName')}</div>
          <div className='confirm-table-cell'>{booking?.offerName}</div>
        </div>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('checkout.numberOfPeopleLabel')}</div>
          {
            (!!booking?.numChildren || !!booking?.numInfants) &&
            <div className='confirm-table-cell'>
              {booking?.numAdults} {t('adults')} 
              {booking?.numChildren ? `/ ${booking?.numChildren} ${t('child')} ` : ''} 
              {booking?.numInfants ? `/ ${booking?.numInfants} ${t('infant')} ` : ''}
            </div>
          }
          {
            (!booking?.numChildren ?? !booking?.numInfants) &&
            <div className='confirm-table-cell'>
              {booking?.numAdults} 
            </div>
          }
        </div>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell confirm-table-cell__total'>{t('confirm.totalCost')}</div>
          <div className='confirm-table-cell confirm-table-cell__total'>
            <strong>{booking?.totalCost} AED</strong>
          </div>
        </div>
      </div>

      <div className='confirm-table'>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.guestName')}</div>
          <div className='confirm-table-cell'>{booking?.guestName}</div>
        </div>
        {booking?.hotelName && (
          <>
            <div className='confirm-table-row'>
              <div className='confirm-table-cell'>{t('confirm.hotelName')}</div>
              <div className='confirm-table-cell'>{booking.hotelName}</div>
            </div>
            <div className='confirm-table-row'>
              <div className='confirm-table-cell'>{t('confirm.roomNumber')}</div>
              <div className='confirm-table-cell'>{booking.roomNumber}</div>
            </div>
          </>
        )}
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.mobileNumber')}</div>
          <div className='confirm-table-cell'>{booking?.phone}</div>
        </div>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.email')}</div>
          <div className='confirm-table-cell'>{booking?.email}</div>
        </div>
      </div>

      <div className='get-ticket-wrap'>
        <div className='get-ticket-info'>
          <IconInfo />{t('confirm.ticketInfo')}
        </div>
        <div className='get-ticket-buttons'>
          {booking?.voucherUrl && (
            <Button
              label={t('confirm.downloadVoucher')}
              color='green'
              customClassName='confirm-button'
              size='large'
              onClickHandler={() => (window.location.href = booking.voucherUrl)}
              iconLeft={() => <IconDownload />}
            />
          )}
          {booking?.ticketsUrl && (
            <Button
              label={t('confirm.downloadTickets')}
              color='blue'
              customClassName='confirm-button'
              size='large'
              onClickHandler={() => (window.location.href = booking.ticketsUrl)}
              iconLeft={() => <IconTicketActive />}
            />
          )}
          {booking?.invoiceUrl && (
            <Button
              label={t('confirm.downloadInvoice')}
              color='dark-blue'
              customClassName='confirm-button'
              size='large'
              onClickHandler={() => (window.location.href = booking.invoiceUrl)}
              iconLeft={() => <IconAccept />}
            />
          )}
        </div>
        <p>
          {t('confirm.information')}
        </p>
      </div>
      <div className='booking-box-wrap confirm-box-contacts'>
        <h3>{t('confirm.reservationTeamContacts')}</h3>
        <p>{t('confirm.questions')}</p>
        <div className='confirm-contact-info'>
          <div className='confirm-contact-info--image'>
            <img src={ContactAvatar} alt='Joan Bantay Fajardo' />
          </div>
          <div className='confirm-contact-info--content'>
            <h5>Joan Bantay Fajardo</h5>
            <ul>
              <li className='confirm-contact-info--tel'>
                <a href={`tel:${supportPhoneNumber}`}>
                  <IconPhone />
                  <span>{supportPhoneNumber.substring(0, 4)}</span> {supportPhoneNumber.substring(4)}
                </a>
              </li>
              <li className='confirm-contact-info--email'>
                <a href={`mailto:${supportEmail}`}>
                  <IconEmail />
                  {supportEmail}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</>
  );
};

export default Confirm;
