import {useMemo} from 'react';
import isEqual from 'date-fns/isEqual';
import {startOfDay} from 'date-fns';
import addMinutes from 'date-fns/addMinutes';
import { Availability } from '../../types/availability';
import { PriceType, TimeSlotType } from '../../types/ticket';

const useCarousel = (availabilities: Availability[], utcOffset: number, timeSlots?: TimeSlotType[], prices?: PriceType[], isExternalTour?: boolean) => {
  const carouselItems = useMemo(() => {
    return (
      prices?.reduce((acc: { date: Date; isEnable: boolean; isExternalTour: boolean; availability?: Availability }[], item) => {
        const date = new Date(item.date);
        const availability = availabilities.find(
          (availabilityItem) =>
            timeSlots?.some(timeSlot => timeSlot?.id === availabilityItem.timeSlotId) && (availabilityItem.left || availabilityItem.leftAdultsTickets) &&
            isEqual(startOfDay(date), startOfDay(new Date(availabilityItem.date)))
        );
        let isMinimumAdvancedBooking = true;
        if (timeSlots) {
          const localDate = new Date(date.valueOf() + utcOffset * 60 * 60 * 1000);
          isMinimumAdvancedBooking = timeSlots?.some(timeSlot => new Date((new Date()).valueOf() + utcOffset * 60 * 60 * 1000 + date.getTimezoneOffset() * 60 * 1000) < addMinutes(localDate, timeSlot.startTime - (timeSlot.minimumAdvancedBooking * 60)));
        }
        const isEnable = !!availability && isMinimumAdvancedBooking && (availability.left > 0 || (availability.leftAdultsTickets && availability.leftAdultsTickets > 0));
        acc.push({
          date: date,
          isEnable: Boolean(isEnable),
          availability: availability,
          isExternalTour: !!isExternalTour
        });
        return acc;
      }, []) || []
    );
  }, [timeSlots, prices, availabilities, utcOffset, isExternalTour]);

  return {
    carouselItems
  };
};

export default useCarousel;