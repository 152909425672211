import { fetchRequest } from './index';
import { agencyGuid, apiUrl, tourId } from '../../config/constants';
import { PickUpTime, PriceType, TicketType, TicketStock } from '../../types/ticket';
import { Hotel } from '../../types/hotel';
import format from 'date-fns/format';
import { addDays } from 'date-fns';

const getTickets = async (currency: string): Promise<TicketType> => {
  const ticket = await fetchRequest.get(`${apiUrl}/widgets/tour/${tourId}`, { currencyCode: currency, agencyGuid: agencyGuid });
  if (ticket) return ticket;
  throw new Error('Не удалось получить данные');
};

const getHotels = async (countryId: number): Promise<Hotel[]> =>
  await fetchRequest.get(`${apiUrl}/hotels/listbase`, { countryId: countryId });

const getOffersPrices = async (offerId: number, from: Date, currency: string): Promise<PriceType[]> =>
  await fetchRequest.get(`${apiUrl}/widgets/offersPrices`, {
    ids: offerId,
    from: format(addDays(from, -7), 'yyyy-MM-dd'),
    to: format(addDays(from, 7), 'yyyy-MM-dd'),
    currencyCode: currency,
    AgencyGuid: agencyGuid
  });

const getPickUp = async (pickUpTimeId?: number, hotelId?: number, startTime?: number): Promise<PickUpTime> =>
  await fetchRequest.get(`${apiUrl}/pickuptimes/value`, {
    pickUpTimeId: pickUpTimeId,
    hotelId: hotelId,
    startTime: startTime
  });
const getTicketStock = async (offerId: number): Promise<TicketStock> =>
  await fetchRequest.get(`${apiUrl}/tickets/foroffer/${offerId}`);
const ticket = {
  getTickets,
  getHotels,
  getPickUp,
  getOffersPrices,
  getTicketStock
};

export default ticket;
