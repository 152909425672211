import { supportPhoneNumber, supportEmail, showPhone } from '../../config/constants';
const Footer = () => (
  <footer id='main-footer'>
    <div className='container'>
      <div className='footer-left'>
        <a className='footer-contact--email' href={`mailto:${supportEmail}`}>
          <i />
          {supportEmail}
        </a>
        {showPhone && (
          <a className='footer-contact--phone' href={`tel:${supportPhoneNumber}`}>
            <i />
            <span>{supportPhoneNumber.substring(0, 4)}</span> {supportPhoneNumber.substring(4)}
          </a>
        )}
      </div>
      <div className='footer-right'>
        <a href="https://traveldesk.me/" target="_blank" rel="noopener noreferrer">
          <div className='footer-photo'></div>
        </a>
        <span className='footer-copyright'>© 2024 All Rights Reserved</span>
      </div>
    </div>
  </footer>
);

export default Footer;
